import React from 'react'
import classnames from 'classnames'

export interface BadgeProps {
  status?: string
  className?: string
}

export const Badge: React.FC<BadgeProps> = (props) => {
  const { status, className, ...rest } = props

  const defaultStatusColorMap: { [key: string]: string[] } = {
    grey: ['VOID', 'CREDITED', 'SCHEDULED'],
    red: ['FAILED', 'PAST_DUE', 'CHARGEBACK', 'CANCELLED', 'DISABLED', 'BALANCE_CARRY_FORWARD', 'NO'],
    amber: ['PENDING', 'PROCESSING', 'PARTIALLY_PAID'],
    emerald: ['SUCCEEDED', 'PAID', 'PAID_EXTERNALLY', 'CONSUMED', 'ENABLED', 'CURRENT', 'ACTIVE', 'YES'],
    blue: [''],
  }

  /* 
  Temporary fix to override the styles that the date-picker component is overriding through inline styling
  See git hub issue: https://github.com/onesine/react-tailwindcss-datepicker/issues/171 
  */
  const tailwindColorMap: { [key: string]: string } = {
    'grey-100': '#F3F4F6',
    'grey-800': '#374151',
    'red-100': '#FEE2E2',
    'red-800': '#991B1B',
    'amber-100': '#FDE68A',
    'amber-800': '#92400E',
    'emerald-100': '#D1FAE5',
    'emerald-800': '#064E3B',
    'blue-100': '#BFDBFE',
    'blue-800': '#1E3A8A',
    // add more colors or change colors as required
  }

  const getBackgroundColor = (): string => {
    for (const color in defaultStatusColorMap) {
      if (status != null && defaultStatusColorMap[color].includes(status)) return tailwindColorMap[`${color}-100`]
    }
    return tailwindColorMap['grey-100'] // Default to Tailwind's grey-100 color
  }

  const getTextColor = (): string => {
    for (const color in defaultStatusColorMap) {
      if (status != null && defaultStatusColorMap[color].includes(status)) return tailwindColorMap[`${color}-800`]
    }
    return tailwindColorMap['grey-800'] // Default to Tailwind's grey-800 color
  }

  return (
    <>
      <span
        className={classnames('inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium', className)}
        style={{ backgroundColor: getBackgroundColor(), color: getTextColor() }}
        {...rest}
      >
        {status?.replaceAll('_', ' ')}
      </span>
    </>
  )
}

export default Badge
